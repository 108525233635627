/* eslint-disable sonarjs/no-identical-functions */
/* eslint-disable no-nested-ternary */
/* eslint-disable react/react-in-jsx-scope */
import { Button, DatePicker, Input, Space, Tooltip, message } from 'antd';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { connect, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { createStructuredSelector } from 'reselect';
import { ReactComponent as SortDownIcon } from '../../assets/icons/sort-down-icon.svg';
import { ReactComponent as SortUpIcon } from '../../assets/icons/sort-up-icon.svg';
import DataTable from '../../components/DataTable/DataTable.component';
import {
	CLOSED,
	DEFAULT_LIMIT_START,
	RECONCILIATION_IN_PROGRESS,
	SEARCH_MIN_CHAR,
	countInstanceDataMapped,
	userPermissions,
	userRoles,
} from '../../constants';
import { databaseDateFormat } from '../../constants/country';
import {
	deleteCountInstanceAsync,
	getCountInstanceFiltersStartAsync,
	searchCountInstanceStartAsync,
	updateVarianceAnalysisAsync,
} from '../../redux/CountInstance/CountInstance.actions';
import {
	selectCountInstanceFilters,
	selectCountInstances,
	selectCountInstancesError,
	selectIsCountInstancesFetching,
	selectTotalCountInstanceCount,
} from '../../redux/CountInstance/CountInstance.selectors';
import {
	selectErpType,
	selectLoggedInUserRoles,
	selectUserCountry,
	selectWebPerms,
} from '../../redux/User/User.selector';
import { selectTranslations } from '../../redux/i18n/i18nSlice';
import { getCountryDateFormat } from '../../utils/GetCountryFormats.utils';
import { checkArrayNull } from '../../utils/Verify.utils';

function CountInstancePage({
	webPerms,
	countryCode,
	loggedInUserRoles,
	countInstances,
	searchCountInstance,
	onYearChange,
	errorMessage = null,
	getNextPage,
	isFetching,
	totalRecords,
	resetCountInstanceData,
	deleteCountInstance,
	updateVarianceAnalysisState,
	erpType,
	countInstanceFilters,
	fetchCountInstanceFilters,
}) {
	const t = useSelector(selectTranslations);

	const [searchQuery, setSearchQuery] = useState('');

	const history = useHistory();
	const { pathname } = history.location;

	const dateFormat = getCountryDateFormat(countryCode);
	const [year, setYear] = useState(new Date().getFullYear());
	useEffect(() => {
		if (year > 0) {
			onYearChange(
				erpType,
				!loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)
					? countryCode.join(' ')
					: '',
				searchQuery,
				year,
			);
			fetchCountInstanceFilters(
				erpType,
				!loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)
					? countryCode.join(' ')
					: '',
				year,
			);
		}
	}, [year]);
	const handleTableChange = (pagination, filter, sorter) => {
		getNextPage(
			erpType,
			!loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)
				? countryCode.join(' ')
				: '',
			(pagination.current - 1) * pagination.pageSize,
			pagination.pageSize,
			searchQuery,
			sorter.columnKey,
			sorter.order,
			filter,
			year,
		);
	};

	const rangePicker = (
		setSelectedKeys,
		selectedKeys,
		confirm,
		clearFilters,
	) => {
		const handleDateChange = dates => {
			if (dates && dates.length === 2) {
				const formattedDates = dates.map(date => date.format('YYYY-MM-DD'));
				setSelectedKeys(formattedDates);
			} else {
				setSelectedKeys([]);
			}
		};

		const handleReset = () => {
			setSelectedKeys([]);
			clearFilters();
		};
		return (
			<div style={{ padding: 8 }}>
				<Space>
					<DatePicker.RangePicker
						format={databaseDateFormat}
						value={
							selectedKeys.length === 2
								? selectedKeys.map(date => moment(date, databaseDateFormat))
								: []
						}
						onChange={handleDateChange}
					/>
					<Space>
						<Button
							type="primary"
							onClick={() => confirm()}
							size="small"
							style={{ width: 90 }}>
							Ok
						</Button>
						<Button
							disabled={!selectedKeys.length}
							onClick={handleReset}
							size="small"
							style={{ width: 90 }}>
							Reset
						</Button>
					</Space>
				</Space>
			</div>
		);
	};

	const countInstanceColumns = [
		{
			title: t.ScheduledCountID,
			dataIndex: 'scheduledCountID',
			key: 'scheduledCountID',
			ellipsis: {
				showTitle: false,
			},
			width: 200,

			render: scheduledCountID => (
				<Tooltip placement="topLeft" title={scheduledCountID}>
					{scheduledCountID}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.CACNumber,
			dataIndex: 'cac',
			key: 'cac',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: cac => (
				<Tooltip placement="topLeft" title={cac}>
					{cac}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},

		{
			title: t.ShipToNumber,
			dataIndex: 'shipTo',
			key: 'shipTo',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: shipTo => (
				<Tooltip
					placement="topLeft"
					title={
						shipTo
							? shipTo.map(item => (
									<div>{`${item} ,`.replace(/,\s*$/, '')}</div>
							  ))
							: null
					}>
					{shipTo
						? shipTo.map(item => <div>{`${item} ,`.replace(/,\s*$/, '')}</div>)
						: null}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.NumOfProducts,
			dataIndex: 'numberOfProducts',
			key: 'numberOfProducts',
			width: 200,
			render: numberOfProducts => numberOfProducts,
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.ProductsQuantity,
			dataIndex: 'productQuantity',
			key: 'productQuantity',
			width: 200,
			render: productQuantity => productQuantity,
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.ExpectedCountStartDate,
			dataIndex: 'expectedStartDate',
			key: 'expectedStartDate',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: expectedStartDate => (
				<Tooltip
					placement="topLeft"
					title={moment(expectedStartDate, databaseDateFormat).format(
						dateFormat,
					)}>
					{expectedStartDate
						? moment(expectedStartDate, databaseDateFormat).format(dateFormat)
						: ''}
				</Tooltip>
			),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => rangePicker(setSelectedKeys, selectedKeys, confirm, clearFilters),
		},
		{
			title: t.ActualCountStartDate,
			dataIndex: 'actualStartDate',
			key: 'actualStartDate',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: actualStartDate => (
				<Tooltip
					placement="topLeft"
					title={moment(actualStartDate, databaseDateFormat).format(
						dateFormat,
					)}>
					{actualStartDate
						? moment(actualStartDate, databaseDateFormat).format(dateFormat)
						: ''}
				</Tooltip>
			),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => rangePicker(setSelectedKeys, selectedKeys, confirm, clearFilters),
		},
		{
			title: t.ExpectedCountEndDate,
			dataIndex: 'expectedEndDate',
			key: 'expectedEndDate',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: expectedEndDate => (
				<Tooltip
					placement="topLeft"
					title={moment(expectedEndDate, databaseDateFormat).format(
						dateFormat,
					)}>
					{expectedEndDate
						? moment(expectedEndDate, databaseDateFormat).format(dateFormat)
						: ''}
				</Tooltip>
			),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => rangePicker(setSelectedKeys, selectedKeys, confirm, clearFilters),
		},
		{
			title: t.ActualCountEndDate,
			dataIndex: 'actualEndDate',
			key: 'actualEndDate',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: actualEndDate => (
				<Tooltip
					placement="topLeft"
					title={moment(actualEndDate, databaseDateFormat).format(dateFormat)}>
					{actualEndDate
						? moment(actualEndDate, databaseDateFormat).format(dateFormat)
						: ''}
				</Tooltip>
			),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => rangePicker(setSelectedKeys, selectedKeys, confirm, clearFilters),
		},
		{
			title: t.ReconcilationEndDate,
			dataIndex: 'reconciliationEndDate',
			key: 'reconciliationEndDate',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: reconciliationEndDate => (
				<Tooltip
					placement="topLeft"
					title={moment(reconciliationEndDate, databaseDateFormat).format(
						dateFormat,
					)}>
					{reconciliationEndDate
						? moment(reconciliationEndDate, databaseDateFormat).format(
								dateFormat,
						  )
						: ''}
				</Tooltip>
			),
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => rangePicker(setSelectedKeys, selectedKeys, confirm, clearFilters),
		},

		{
			title: t.DaysOfOpening,
			dataIndex: 'daysOpen',
			key: 'daysOpen',
			width: 200,
			render: daysOpen => daysOpen,
			// eslint-disable-next-line react/no-unstable-nested-components
			filterDropdown: ({
				setSelectedKeys,
				selectedKeys,
				confirm,
				clearFilters,
			}) => (
				<div style={{ padding: 8 }}>
					<Input.Group compact={false}>
						<Input
							type="number"
							style={{ width: '28%', textAlign: 'center', marginRight: 2 }}
							placeholder="From"
							value={selectedKeys[0]}
							onChange={e => setSelectedKeys([e.target.value, selectedKeys[1]])}
						/>
						<Input
							style={{
								width: '28%',
								textAlign: 'center',
								marginRight: 2,
							}}
							type="number"
							placeholder="To"
							value={selectedKeys[1]}
							onChange={e => setSelectedKeys([selectedKeys[0], e.target.value])}
						/>
						<Button
							type="primary"
							onClick={() => confirm()}
							size="small"
							style={{ width: '20%', height: '38px', marginRight: 2 }}>
							Ok
						</Button>
						<Button
							disabled={!selectedKeys.length}
							onClick={() => clearFilters()}
							size="small"
							style={{ width: '20%', height: '38px', marginRight: 2 }}>
							Reset
						</Button>
					</Input.Group>
				</div>
			),
		},
		{
			title: t.ScheduleStatus,
			dataIndex: 'status',
			key: 'status',
			width: 200,
			render: status => status,
			filters: countInstanceFilters?.status?.map(scheduleStatus => {
				/* filter as a schedule status */
				const filter = { text: '', value: '' };
				filter.text = scheduleStatus.toUpperCase();
				filter.value = scheduleStatus;
				/* return filter value */
				return filter;
			}),
		},
		{
			title: t.Operator,
			dataIndex: 'operator',
			key: 'operator',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: operator => (
				<Tooltip placement="topLeft" title={operator}>
					{operator}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.AssignedApprover,
			dataIndex: 'approver',
			key: 'approver',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: approver => (
				<Tooltip placement="topLeft" title={approver}>
					{approver}
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.InitialUnitVariance,
			dataIndex: 'initTotQuantVar',
			key: 'initTotQuantVar',
			width: 200,
			render: initTotQuantVar => initTotQuantVar,
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.FinalUnitVariance,
			dataIndex: 'finalTotQuantVar',
			key: 'finalTotQuantVar',
			width: 200,
			render: finalTotQuantVar => finalTotQuantVar,
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.InitialVarianceValue,
			dataIndex: 'initTotQuantVal',
			key: 'initTotQuantVal',
			width: 200,
			render: initTotQuantVal => initTotQuantVal,
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.FinalVarianceValue,
			dataIndex: 'finalTotQuantVal',
			key: 'finalTotQuantVal',
			width: 200,
			render: finalTotQuantVal => finalTotQuantVal,
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.CountAccuracy,
			dataIndex: 'countAccuracy',
			key: 'countAccuracy',
			width: 200,
			render: countAccuracy => countAccuracy,
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},
		{
			title: t.VarianceAnalysisStatus,
			dataIndex: 'variance_status',
			key: 'variance_status',
			ellipsis: {
				showTitle: false,
			},
			width: 300,
			render: varianceAnalysisStatus => (
				<Tooltip placement="topLeft" title={varianceAnalysisStatus}>
					{varianceAnalysisStatus}
				</Tooltip>
			),
			filters: countInstanceFilters?.variance_status?.map(varianceStatus => {
				/* filter as a bn */
				const filter = { text: '', value: '' };
				filter.text = varianceStatus.toUpperCase();
				filter.value = varianceStatus;
				/* return filter value */
				return filter;
			}),
		},
		{
			title: t.VarianceLastApprovalReceived,
			dataIndex: 'variance_approval',
			key: 'variance_approval',
			ellipsis: {
				showTitle: false,
			},
			width: 250,
			render: VarianceLastApprovalReceived => (
				<Tooltip placement="topLeft" title={VarianceLastApprovalReceived}>
					<div className="flex flex-col ">
						{VarianceLastApprovalReceived?.split('/').map(approvers => (
							<div className="pt-2">{`- ${approvers}`}</div>
						))}
					</div>
				</Tooltip>
			),
			sortDirections: ['desc', 'asc', 'desc'],
			sorter: true,
		},

		{
			title: t.Country,
			dataIndex: 'country',
			key: 'country',
			ellipsis: {
				showTitle: false,
			},
			width: 200,
			render: country => (
				<Tooltip placement="topLeft" title={country}>
					{country}
				</Tooltip>
			),
			filters: countInstanceFilters?.country?.map(country => {
				/* filter as a franchise */
				const filter = { text: '', value: '' };
				filter.text = country.toUpperCase();
				filter.value = country;
				/* return filter value */
				return filter;
			}),
		},
	];

	const callBack = data => {
		history.replace(
			`${pathname}/${data.scheduledCountID}/variance-analysis/${encodeURI(
				data.id,
			)}/${data.country}`,
		);
	};

	const handleVAButtonClick = data => {
		if (data.status === RECONCILIATION_IN_PROGRESS || data.status === CLOSED)
			return history.replace(
				`${pathname}/${data?.id}/variance-analysis/${encodeURI(data.id)}/${
					data.country
				}`,
			);

		return updateVarianceAnalysisState(data, callBack);
	};

	const handleDelete = data => {
		deleteCountInstance(
			!loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)
				? countryCode.join(' ')
				: '',
			data,
		);
	};

	const filterIcons = columnKey => {
		switch (columnKey) {
			case 'variance_status':
			case 'daysOpen':
			case 'expectedStartDate':
			case 'actualStartDate':
			case 'expectedEndDate':
			case 'actualEndDate':
			case 'reconciliationEndDate':
			case 'country':
			case 'status':
				return <div />;
			default:
				return (
					<div className="block py-1">
						<div className="h-2">
							<SortUpIcon />
						</div>
						<div>
							<SortDownIcon />
						</div>
					</div>
				);
		}
	};
	return (
		<div>
			{errorMessage ? message.error(errorMessage) : null}
			<DataTable
				totalRecords={totalRecords}
				columnsInit={countInstanceColumns.map(column =>
					column.title
						? {
								...column,
								title: (
									<div className="flex font-bold  justify-between">
										<div className="w-4/5 whitespace-normal">
											{column.title}
										</div>
										{filterIcons(column.key)}
									</div>
								),
						  }
						: column,
				)}
				exportHeaders={Array(countInstanceColumns.map(column => column.title))}
				columnsOption={countInstanceColumns.map(column => ({
					label: column.title,
					value: column.dataIndex,
				}))}
				data={countInstances || null}
				perPage={DEFAULT_LIMIT_START}
				searchData={(e, searchYear) => {
					searchCountInstance(
						erpType,
						!loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)
							? countryCode.join(' ')
							: '',
						e,
						searchYear,
					);
					setSearchQuery(e);
				}}
				resetData={() => {
					resetCountInstanceData(
						erpType,
						!loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)
							? countryCode.join(' ')
							: '',
						year,
					);
					setSearchQuery('');
				}}
				hasDetail
				deleteData={
					webPerms.includes(userPermissions.COUNT_INSTANCE_MANAGEMENT) &&
					!loggedInUserRoles.includes(userRoles.SYSTEM_ADMIN)
						? e => handleDelete(e)
						: null
				}
				varianceAnalysisState={
					webPerms.includes(userPermissions.COUNT_INSTANCE_MANAGEMENT) ||
					webPerms.includes(userPermissions.COUNT_INSTANCE_GRID) ||
					webPerms.includes(userPermissions.VARIANCE_ANALYSIS_MANAGEMENT) ||
					webPerms.includes(userPermissions.VARIANCE_APPROVAL_FUNCTION)
						? e => handleVAButtonClick(e)
						: null
				}
				mappedData={countInstanceDataMapped}
				callerComponent="Count Instance"
				csvData={
					checkArrayNull(countInstances)
						? countInstances.map(({ id, ...keepAttrs }) => keepAttrs)
						: null
				}
				handleTableChange={handleTableChange}
				isFetching={isFetching}
				year={year}
				setYear={setYear}
				enableYearSelection
			/>
		</div>
	);
}

const mapStateToProps = createStructuredSelector({
	webPerms: selectWebPerms,
	countryCode: selectUserCountry,
	loggedInUserRoles: selectLoggedInUserRoles,
	countInstances: selectCountInstances,
	errorMessage: selectCountInstancesError,
	isFetching: selectIsCountInstancesFetching,
	totalRecords: selectTotalCountInstanceCount,
	erpType: selectErpType,
	countInstanceFilters: selectCountInstanceFilters,
});

const mapDispatchToProps = dispatch => ({
	searchCountInstance: (erpType, countryCode, query, year) => {
		if (query.length > SEARCH_MIN_CHAR)
			dispatch(
				searchCountInstanceStartAsync(erpType, countryCode, query, year),
			);
	},
	onYearChange: (erpType, countryCode, query, year) => {
		dispatch(searchCountInstanceStartAsync(erpType, countryCode, query, year));
	},
	getNextPage: (
		erpType,
		countryCode,
		offset,
		limit,
		query,
		sortKey,
		sortOrder,
		filter,
		year,
	) =>
		dispatch(
			searchCountInstanceStartAsync(
				erpType,
				countryCode,
				query,
				year,
				offset,
				limit,
				sortKey,
				sortOrder,
				filter,
			),
		),
	resetCountInstanceData: (erpType, countryCode, year) =>
		dispatch(searchCountInstanceStartAsync(erpType, countryCode, '', year)),
	deleteCountInstance: (countryCode, data) =>
		dispatch(deleteCountInstanceAsync(countryCode, data)),
	updateVarianceAnalysisState: (data, callBack) =>
		dispatch(updateVarianceAnalysisAsync(data, callBack)),
	fetchCountInstanceFilters: (erpType, country, selectedYear) =>
		dispatch(getCountInstanceFiltersStartAsync(erpType, country, selectedYear)),
});
export default connect(mapStateToProps, mapDispatchToProps)(CountInstancePage);
